import './App.css';

function App() {
  return (
      <article>
        <h3>A-SCEND is currently down for maintenance</h3>
        <p>We apologize for the inconvenience, but will be back shortly.</p>
        <br />
        <span style={{fontSize: 10}}>
          <a href="https://a-lign.com/privacy-policy" target="_blank" rel="noreferrer">Privacy Policy</a> | <a href="https://a-lign.com/cookie-policy" target="_blank" rel="noreferrer">Cookie Policy</a> | <a href="https://a-lign.com/termsofuse" target="_blank" rel="noreferrer">Terms of Use</a>
        </span>
      </article>
  );
}

export default App;
